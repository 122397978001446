<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("programme_selections") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form
                      ref="observer"
                      tag="form"
                      @submit.prevent="startRecordUpdate()"
                    >
                      <!-- First Programme -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("first_choice_programme") }}
                        </div>

                        <div class="grid">
                          <!-- first_choice -->
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("select_faculty") }} *
                            </div>
                            <div class="">
                              <Dropdown
                                class="w-full"
                                :class="
                                  getErrorClass('first_programme_school_id')
                                "
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlfirst_programme_school_id"
                                v-model="formData.first_programme_school_id"
                                :options="facultiesList"
                                :label="$t('first_programme_school_id')"
                                :placeholder="$t('select')"
                                @change="validateFaculties('1')"
                              >
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div
                                      class="ml-2"
                                      v-if="slotProps.option.image"
                                    >
                                      <Avatar
                                        :image="
                                          $utils.setImgUrl(
                                            slotProps.option.image
                                          )
                                        "
                                      />
                                    </div>
                                    <div>
                                      <div>
                                        {{ slotProps.option.label }}
                                      </div>
                                      <div
                                        v-if="slotProps.option.caption"
                                        class="text-sm text-500"
                                      >
                                        {{ slotProps.option.caption }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                            </div>
                          </div>
                          <!-- first_programme -->
                          <div class="col-12 md:col-12">
                            <div class="grid">
                              <div class="col-12 md:col-12">
                                <Divider
                                  align="left"
                                  type="dashed"
                                  class="my-2 p-divider-bottom"
                                >
                                  <div class="mb-2 text-sm text-primary">
                                    {{ $t("first_choice_programme") }} *
                                  </div>
                                </Divider>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    :filter="true"
                                    :class="getErrorClass('first_programme_id')"
                                    optionLabel="label"
                                    optionValue="value"
                                    ref="ctrlfirst_programme_id"
                                    v-model="formData.first_programme_id"
                                    :options="firstProgrammesList"
                                    :label="$t('first_choice_programme')"
                                    :placeholder="$t('select')"
                                    @change="
                                      validateProgrammeDuplicate('1')
                                    "
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.label }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .programme_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-400"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .programme_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </Dropdown>
                                </div>
                                <!-- first_programme.cut_off_point -->
                                <div class="my-2">
                                  <Badge
                                    v-if="
                                      first_programme &&
                                      first_programme.cut_off_point
                                    "
                                    :value="
                                      $t('cut_off_point') +
                                      ' : ' +
                                      first_programme.cut_off_point
                                    "
                                    severity="info"
                                    class="mr-2"
                                  ></Badge>
                                  <Badge
                                    v-if="
                                      first_programme &&
                                      first_programme.programme_required_electives
                                    "
                                    :value="
                                      $t('required_electives') +
                                      ' : ' +
                                      first_programme.programme_required_electives
                                    "
                                    severity="warning"
                                    class="mr-2"
                                  ></Badge>
                                </div>
                              </div>
                              <!-- first_programme.programme_options -->
                              <div
                                v-if="
                                  first_programme &&
                                  first_programme.programme_options
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_options") }}
                                </div>
                                <div class="">
                                  <MultiSelect
                                    class="w-full"
                                    ref="ctrlfirst_programme_options"
                                    v-model="formData.first_programme_options"
                                    :options="
                                      JSON.parse(
                                        first_programme.programme_options
                                      )
                                    "
                                    optionLabel="option_name"
                                    optionValue="option_name"
                                    :placeholder="$t('select')"
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.option_name }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .option_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-300"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .option_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </MultiSelect>
                                </div>
                              </div>
                              <!-- first_programme.programme_campuses -->
                              <div
                                v-if="
                                  first_programme &&
                                  first_programme.programme_campuses
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_campus") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlfirst_programme_campus"
                                    v-model="formData.first_programme_campus"
                                    :options="
                                      $utils.toArray(
                                        first_programme.programme_campuses
                                      )
                                    "
                                    :label="$t('campus')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                              <!-- first_programme.programme_sessions -->
                              <div
                                v-if="
                                  first_programme &&
                                  first_programme.programme_sessions
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_session") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlfirst_programme_session"
                                    v-model="formData.first_programme_session"
                                    :options="
                                      $utils.toArray(
                                        first_programme.programme_sessions
                                      )
                                    "
                                    :label="$t('programme_sessions')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Second Programme -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("second_choice_programme") }}
                        </div>

                        <div class="grid">
                          <!-- second_choice_programme -->
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("select_faculty") }} *
                            </div>
                            <div class="">
                              <Dropdown
                                class="w-full"
                                :class="
                                  getErrorClass('second_programme_school_id')
                                "
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlsecond_programme_school_id"
                                v-model="formData.second_programme_school_id"
                                :options="facultiesList"
                                :label="$t('second_programme_school_id')"
                                :placeholder="$t('select')"
                                @change="validateFaculties('2')"
                              >
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div
                                      class="ml-2"
                                      v-if="slotProps.option.image"
                                    >
                                      <Avatar
                                        :image="
                                          $utils.setImgUrl(
                                            slotProps.option.image
                                          )
                                        "
                                      />
                                    </div>
                                    <div>
                                      <div>
                                        {{ slotProps.option.label }}
                                      </div>
                                      <div
                                        v-if="slotProps.option.caption"
                                        class="text-sm text-500"
                                      >
                                        {{ slotProps.option.caption }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                            </div>
                          </div>
                          <!-- second_programme -->
                          <div class="col-12 md:col-12">
                            <div class="grid">
                              <div class="col-12 md:col-12">
                                <Divider
                                  align="left"
                                  type="dashed"
                                  class="my-2 p-divider-bottom"
                                >
                                  <div class="mb-2 text-sm text-primary">
                                    {{ $t("second_choice_programme") }} *
                                  </div>
                                </Divider>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    :class="
                                      getErrorClass('second_programme_id')
                                    "
                                     :filter="true"
                                    optionLabel="label"
                                    optionValue="value"
                                    ref="ctrlsecond_programme_id"
                                    v-model="formData.second_programme_id"
                                    :options="secondProgrammesList"
                                    :label="$t('second_choice_programme')"
                                    :placeholder="$t('select')"
                                    @change="
                                      validateProgrammeDuplicate('2')
                                    "
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.label }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .programme_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-400"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .programme_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </Dropdown>
                                </div>
                                <div class="my-2">
                                  <Badge
                                    :value="
                                      $t('cut_off_point') +
                                      ' : ' +
                                      second_programme.cut_off_point
                                    "
                                    v-if="
                                      second_programme &&
                                      second_programme.cut_off_point
                                    "
                                    severity="info"
                                    class="mr-2"
                                  ></Badge>
                                  <Badge
                                    :value="
                                      $t('required_electives') +
                                      ' : ' +
                                      second_programme.programme_required_electives
                                    "
                                    v-if="
                                      second_programme &&
                                      second_programme.programme_required_electives
                                    "
                                    severity="warning"
                                    class="mr-2"
                                  ></Badge>
                                </div>
                              </div>
                              <!-- second_programme.programme_options -->
                              <div
                                v-if="
                                  second_programme &&
                                  second_programme.programme_options
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_options") }}
                                </div>
                                <div class="">
                                  <MultiSelect
                                    class="w-full"
                                    ref="ctrlsecond_programme_options"
                                    v-model="formData.second_programme_options"
                                    :options="
                                      JSON.parse(
                                        second_programme.programme_options
                                      )
                                    "
                                    optionLabel="option_name"
                                    optionValue="option_name"
                                    :placeholder="$t('select')"
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.option_name }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .option_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-300"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .option_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </MultiSelect>
                                </div>
                              </div>
                              <!-- second_programme.programme_campuses -->
                              <div
                                v-if="
                                  second_programme &&
                                  second_programme.programme_campuses
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_campus") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlsecond_programme_campus"
                                    v-model="formData.second_programme_campus"
                                    :options="
                                      $utils.toArray(
                                        second_programme.programme_campuses
                                      )
                                    "
                                    :label="$t('campus')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                              <!-- second_programme.programme_sessions -->
                              <div
                                v-if="
                                  second_programme &&
                                  second_programme.programme_sessions
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_session") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlsecond_programme_session"
                                    v-model="formData.second_programme_session"
                                    :options="
                                      $utils.toArray(
                                        second_programme.programme_sessions
                                      )
                                    "
                                    :label="$t('programme_sessions')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Third Programme -->
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("third_choice_programme") }}
                        </div>

                        <div class="grid">
                          <!-- third_choice_programme -->
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{ $t("select_faculty") }} *
                            </div>
                            <div class="">
                              <Dropdown
                                class="w-full"
                                :class="
                                  getErrorClass('third_programme_school_id')
                                "
                                optionLabel="label"
                                optionValue="value"
                                ref="ctrlthird_programme_school_id"
                                v-model="formData.third_programme_school_id"
                                :options="facultiesList"
                                :label="$t('third_programme_school_id')"
                                :placeholder="$t('select')"
                                @change="validateFaculties('3')"
                              >
                                <template #option="slotProps">
                                  <div class="flex align-items-center">
                                    <div
                                      class="ml-2"
                                      v-if="slotProps.option.image"
                                    >
                                      <Avatar
                                        :image="
                                          $utils.setImgUrl(
                                            slotProps.option.image
                                          )
                                        "
                                      />
                                    </div>
                                    <div>
                                      <div>
                                        {{ slotProps.option.label }}
                                      </div>
                                      <div
                                        v-if="slotProps.option.caption"
                                        class="text-sm text-500"
                                      >
                                        {{ slotProps.option.caption }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </Dropdown>
                            </div>
                          </div>
                          <!-- third_programme -->
                          <div class="col-12 md:col-12">
                            <div class="grid">
                              <div class="col-12 md:col-12">
                                <Divider
                                  align="left"
                                  type="dashed"
                                  class="my-2 p-divider-bottom"
                                >
                                  <div class="mb-2 text-sm text-primary">
                                    {{ $t("third_choice_programme") }} *
                                  </div>
                                </Divider>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    :class="getErrorClass('third_programme_id')"
                                    optionValue="value"
                                    optionLabel="label"
                                    ref="ctrlthird_programme_id"
                                    :filter="true"
                                    v-model="formData.third_programme_id"
                                    :options="thirdProgrammesList"
                                    :label="$t('third_choice_programme')"
                                    :placeholder="$t('select')"
                                    @change="
                                      validateProgrammeDuplicate('3')
                                    "
                                  >
                                  
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.label }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .programme_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-400"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .programme_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </Dropdown>
                                </div>
                                <div class="my-2">
                                  <Badge
                                    v-if="
                                      third_programme &&
                                      third_programme.cut_off_point
                                    "
                                    :value="
                                      $t('cut_off_point') +
                                      ' : ' +
                                      third_programme.cut_off_point
                                    "
                                    severity="info"
                                    class="mr-2"
                                  ></Badge>
                                  <Badge
                                    v-if="
                                      third_first_programme &&
                                      third_programme.programme_required_electives
                                    "
                                    :value="
                                      $t('required_electives') +
                                      ' : ' +
                                      third_programme.programme_required_electives
                                    "
                                    severity="warning"
                                    class="mr-2"
                                  ></Badge>
                                </div>
                              </div>
                              <!-- third_programme.programme_options -->
                              <div
                                v-if="
                                  third_programme &&
                                  third_programme.programme_options
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_options") }}
                                </div>
                                <div class="">
                                  <MultiSelect
                                    class="w-full"
                                    ref="ctrlthird_programme_options"
                                    v-model="formData.third_programme_options"
                                    :options="
                                      JSON.parse(
                                        third_programme.programme_options
                                      )
                                    "
                                    optionLabel="option_name"
                                    optionValue="option_name"
                                    :placeholder="$t('select')"
                                  >
                                    <template #option="slotProps">
                                      <div class="flex align-items-center">
                                        <div>
                                          <div>
                                            {{ slotProps.option.option_name }}
                                          </div>
                                          <div
                                            v-if="
                                              slotProps.option
                                                .option_required_electives
                                            "
                                            class="text-sm text-500"
                                          >
                                            <span class="text-purple-300"
                                              >{{
                                                $t("required_electives")
                                              }}:</span
                                            >
                                            {{
                                              slotProps.option
                                                .option_required_electives
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </MultiSelect>
                                </div>
                              </div>
                              <!-- third_programme.programme_campuses -->
                              <div
                                v-if="
                                  third_programme &&
                                  third_programme.programme_campuses
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_campus") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlthird_programme_campus"
                                    v-model="formData.third_programme_campus"
                                    :options="
                                      $utils.toArray(
                                        third_programme.programme_campuses
                                      )
                                    "
                                    :label="$t('campus')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                              <!-- third_programme.programme_sessions -->
                              <div
                                v-if="
                                  third_programme &&
                                  third_programme.programme_sessions
                                "
                                class="col-12 md:col-6"
                              >
                                <div class="mb-2 font-bold text-sm">
                                  {{ $t("select_programme_session") }}
                                </div>
                                <div class="">
                                  <Dropdown
                                    class="w-full"
                                    ref="ctrlthird_programme_session"
                                    v-model="formData.third_programme_session"
                                    :options="
                                      $utils.toArray(
                                        third_programme.programme_sessions
                                      )
                                    "
                                    :label="$t('programme_sessions')"
                                    :placeholder="$t('select')"
                                  >
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button
                          type="submit"
                          :label="$t('save_and_continue')"
                          icon="pi pi-send"
                          :loading="saving"
                        />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "programmesdataApplicantsPage",
  components: {},
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsprogrammesdata",
    },
    pagePath: {
      type: String,
      default: "applicants/programmesdata",
    },
    apiPath: {
      type: String,
      default: "applicants/programmesdata",
    },
    pageIndex: { type: Number, default: 4 },
  },
  data() {
    return {
      formData: {
        first_programme_id: "",
        second_programme_id: "",
        third_programme_id: "",
        first_programme_school_id: "",
        second_programme_school_id: "",
        third_programme_school_id: "",
        first_programme_options: "",
        second_programme_options: "",
        third_programme_options: "",
        first_programme_session: "",
        second_programme_session: "",
        third_programme_session: "",
        first_programme_campus: "",
        second_programme_campus: "",
        third_programme_campus: "",
      },
      first_programme: "",
      second_programme: "",
      third_programme: "",
      submitted: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("applicants", [
      "facultiesList",
      "firstProgrammesList",
      "secondProgrammesList",
      "thirdProgrammesList",
      "programmeDataRecord"
    ]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      first_programme_id: { required },
      second_programme_id: { required },
      third_programme_id: { required },
      first_programme_school_id: { required },
      second_programme_school_id: { required },
      third_programme_school_id: { required },
      first_programme_options: {},
      second_programme_options: {},
      third_programme_options: {},
      first_programme_session: {},
      second_programme_session: {},
      third_programme_session: {},
      first_programme_campus: {},
      second_programme_campus: {},
      third_programme_campus: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", [
      "getFaculties",
      "getFacultiesProgrammes",
      "updateProgrammeData",
      "fetchProgrammeData",
    ]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchProgrammeData(url).then(
        (response) => {
          // this.programmeDataRecord = response.data;
          this.formData = response.data;

          this.getFaculties();
          this.loadProgrammeDataFields();

          this.loading = false;
          this.ready = true;

          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateProgrammeData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    async loadProgrammes(school_id, selection_category, reload_programmes) {
      let url = `components_data/institution_programmes_option_list?lookup_id=${school_id}`;
      let payload = selection_category;
      let reload = reload_programmes;
      let data = { url, payload, reload };
      await this.getFacultiesProgrammes(data);
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.formData.first_programme_options = this.$utils.toArray(
        this.formData.first_programme_options
      );
      this.formData.second_programme_options = this.$utils.toArray(
        this.formData.second_programme_options
      );
      this.formData.third_programme_options = this.$utils.toArray(
        this.formData.third_programme_options
      );
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        first_programme_id: "",
        second_programme_id: "",
        third_programme_id: "",
        first_programme_school_id: "",
        second_programme_school_id: "",
        third_programme_school_id: "",
        first_programme_options: "",
        second_programme_options: "",
        third_programme_options: "",
        first_programme_session: "",
        second_programme_session: "",
        third_programme_session: "",
        first_programme_campus: "",
        second_programme_campus: "",
        third_programme_campus: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    validateFaculties(index) {
      // Validate Institution Selections
      if (index == "1" && this.formData.first_programme_school_id) {
        // Fetch Faculty Programmes
        if (this.formData.first_programme_school_id) {
          this.loadProgrammes(
            this.formData.first_programme_school_id,
            index,
            true
          );
        }
      } else if (index == "2" && this.formData.second_programme_school_id) {
        // Fetch Faculty Programmes
        if (this.formData.second_programme_school_id) {
          this.loadProgrammes(
            this.formData.second_programme_school_id,
            index,
            true
          );
        }
      } else {
        // Fetch Institution Programmes
        if (this.formData.third_programme_school_id) {
          this.loadProgrammes(
            this.formData.third_programme_school_id,
            index,
            true
          );
        }
      }
    },
    validateProgrammeDuplicate(prog_index) {
      if (prog_index == "1") {
        if (
          this.formData.first_programme_id ==
            this.formData.second_programme_id ||
          this.formData.first_programme_id == this.formData.third_programme_id
        ) {
          this.formData.first_programme_id = null;
          this.first_programme = "";
          this.showPageRequestError(
            this.$t("duplicate_programme_selection_found")
          );
        } else {
          this.first_programme = this.firstProgrammesList.find(
            (e) => e.value == this.formData.first_programme_id
          );
        }
      } else if (prog_index == "2") {
        if (
          this.formData.second_programme_id ==
            this.formData.first_programme_id ||
          this.formData.second_programme_id == this.formData.third_programme_id
        ) {
          this.formData.second_programme_id = null;
          this.second_programme = "";
          this.showPageRequestError(
            this.$t("duplicate_programme_selection_found")
          );
        } else {
          this.second_programme = this.secondProgrammesList.find(
            (e) => e.value == this.formData.second_programme_id
          );
        }
      } else {
        if (
          this.formData.third_programme_id ==
            this.formData.second_programme_id ||
          this.formData.third_programme_id == this.formData.first_programme_id
        ) {
          this.formData.third_programme_id = null;
          this.third_programme = "";
          this.showPageRequestError(
            this.$t("duplicate_programme_selection_found")
          );
        } else {
          this.third_programme = this.thirdProgrammesList.find(
            (e) => e.value == this.formData.third_programme_id
          );
        }
      }
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
    async loadProgrammeDataFields() {
      // First Faculty
      this.loadProgrammes(this.formData.first_programme_school_id, "1", false)
        .then((res) => {
          this.first_programme = this.firstProgrammesList.find(
            (e) => e.value == this.formData.first_programme_id
          );
        })
        .catch((e) => {});

      // Second Faculty
      this.loadProgrammes(this.formData.second_programme_school_id, "2", false)
        .then((res) => {
          this.second_programme = this.secondProgrammesList.find(
            (e) => e.value == this.formData.second_programme_id
          );
        })
        .catch((e) => {});

      // Third Faculty
      this.loadProgrammes(
        this.formData.third_programme_school_id,
        "3",
        false
      )
        .then((res) => {
          this.third_programme = this.thirdProgrammesList.find(
            (e) => e.value == this.formData.third_programme_id
          );
        })
        .catch((e) => {});
    },
  },
  watch: {},
  async mounted() {
    await this.load();
  },
  async created() {},
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>
